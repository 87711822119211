import React from "react";
import BodyClassName from "react-body-classname";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/juster.css";
import "../css/animation.css";
import Tablet from "../images/integration_tablet.svg";
import Blob from "../images/integration_blob.svg";
import Dropbox from "../images/dropbox_logo.svg";
import CC from "../images/cc_logo.svg";
import WiFi from "../images/wifi_logo.svg";
import Gmail from "../images/gmail_logo.svg";
import { Helmet } from "react-helmet"

export default () => (
  <>
    <BodyClassName className="integration_page" />
    <Helmet>
      <title>Third party apps and integration | Field Inspection App for Adjusters</title>          
      <meta name="description" content="Connect your JustEZ account to Dropbox, Claimcolony, Wifi- Cameras, Outlook/Gmail. Store all the loss data in cloud, transfer photos/ video, sync claim assignments." />
      <meta name="keywords" content="manage inspection assignments, claim management,claim management system,sync claims,sync photos,import photos,inspections,adjusters,insurance adjusters, dropbox, claimcolony"/>
    </Helmet>
    <Header></Header>

    <div class="integration_pg_banner">
      <div class="container">
        <img class="intg_tab" alt="iPad" src={Tablet} />
        <img class="intg_blob" alt="blob" src={Blob} />
        <div class="integrations-info">
          <h2>Integrations</h2>
          <p>
            All the manual work in field inspection process can now be done with
            the help of JustEZ. It integrates with many other programs such as
            Dropbox, Wi-Fi Cameras and Claim management systems; making it easy
            for you to access all apps within a single app only.
          </p>
        </div>
      </div>
    </div>

    <div class="container-main">
      <div class="container integrations_page">
        <div class="integrations_block first">
          <div class="intg_logo_ctr">
            <img alt="Dropbox" src={Dropbox} />
          </div>
          <div class="intg_desc_ctr">
            <div class="heading">Dropbox</div>
            <p class="diff_txt">
              The integration is already built into JustEZ. All you need to do
              is to sign up for a Dropbox account first. Once it is connected,
              you can easily create inspections, sync all media to cloud and
              access it from everywhere.
            </p>
            <p>Why connect JustEZ with Dropbox ?</p>
            <ol class="diff_txt">
              <li>
                One central place to manage inspections received from carriers.
              </li>
              <li>
                Create inspections to get it reflected in app and easily work on
                it.
              </li>
              <li>
                Sync all captured media on cloud to access it from anywhere.
              </li>
              <li>
                Maintain data of all inspections along with their recorded
                details in the most organized way.
              </li>
              <li>The most secure way of storing the data.</li>
            </ol>
            <p>
              <a
                href="https://help.justez.app/settings/connect-with-dropbox"
                target="_blank"
              >
                Learn more on how to connect Dropbox
              </a>
            </p>
          </div>
        </div>
        <div class="integrations_block second">
          <div class="intg_logo_ctr">
            <img alt="ClaimColony" src={CC} />
          </div>
          <div class="intg_desc_ctr">
            <div class="heading">ClaimColony</div>
            <p class="diff_txt">
              JustEZ supports claim management system such as ClaimColony. Now,
              directly sync up on claim statues and notes from claim management
              system.
            </p>
            <p class="diff_txt">
              The integration is already built into JustEZ. If you are a
              ClaimColony user, you need to enter your account credentials to
              get it connected. Once connected, you can easily work on
              inspections assigned to you under your account.
            </p>
            <p>
              <a
                href="https://help.justez.app/settings/connect-with-claimcolony"
                target="_blank"
              >
                Learn more on how to connect ClaimColony
              </a>
            </p>
          </div>
        </div>
        <div class="integrations_block third">
          <div class="intg_logo_ctr">
            <img alt="Wifi-Cameras" src={WiFi} />
          </div>
          <div class="intg_desc_ctr">
            <div class="heading">Wifi-Cameras</div>
            <p class="diff_txt">
              JustEZ supports Wi-Fi Cameras such as Olympus, Nikon. And even if
              your camera does not have an in-built Wi-Fi, you can use any SD
              card that has Wi-Fi capabilities such as Flashair SD card and all
              the media will get synced to inspections automatically.
            </p>
            <p>Why should you connect with Wifi Cameras?</p>
            <ol class="diff_txt">
              <li>
                No need to pull SD card out of camera for importing photos.
              </li>
              <li>Transfer photos from camera within a few minutes.</li>
              <li>
                Create a private connection and sync all photos to its
                corresponding inspection.
              </li>
            </ol>
          </div>
        </div>
        <div class="integrations_block fourth">
          <div class="intg_logo_ctr">
            <img alt="Outlook/Gmail" src={Gmail} />
          </div>
          <div class="intg_desc_ctr">
            <div class="heading">Outlook/Gmail</div>
            <p class="diff_txt">
              JustEZ integrates with almost all email client services. Now ease
              off your task of creating inspections by forwarding emails only.
            </p>
            <p>Forward emails to create inspection</p>
            <ol class="diff_txt">
              <li>
                Easily manage inspections received from different carriers at
                one central place.
              </li>
              <li>
                Simply forward emails from any email client services such as
                Outlook, Gmail etc. to create inspections.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </>
);
